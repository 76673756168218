import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Container, Row, Modal } from 'react-bootstrap';

const Styles = styled.div`
  position: fixed;
  inset: 0px;
  background-color: rgba(0, 0, 0, 0.85);
  z-index: 1000;
  min-height: 100vh;
  min-width: 100vw;

  .lightbox-inner {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .current-image {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    max-width: 100vw;
    max-height: 100vh;
    -ms-content-zooming: none;
    -ms-user-select: none;
    -ms-touch-select: none;
    -ms-touch-action: none;
    touch-action: none;
  }

  .ril__navButtons {
    padding: 40px 30px 40px 30px !important;
    background-color: rgba(0, 0, 0, 0.5);
  }

  .ril__toolbarItem {
    display: inline-block;
    line-height: 50px;
    padding: 0;
    color: #fff;
    font-size: 120%;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-left: 0;
    padding-right: 20px;
    -ms-flex: 0 0 auto;
    -webkit-box-flex: 0;
    flex: 0 0 auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .ril__builtinButton {
    width: 40px;
    height: 35px;
    cursor: pointer;
    border: none;
    opacity: 0.7;
  }

  .ril__toolbarRightSide {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  .ril__toolbarItemChild {
    vertical-align: middle;
    margin: auto;
  }

  .lightbox_preview {
    position: absolute;
    left: 0;
    bottom: 0;
    padding: 0px;
    max-width: none;
    max-height: 100px;
  }

  .lightbox_row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.5);
    min-height: 100px;
  }

  .thumbnail-container {
    min-height: 100px;
    margin: 5px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
  }

  .thumbnail_img {
    max-height: 80px;
    width: auto;
    margin: 5px;
    opacity: 0.7;
    margin: auto;

    :hover {
      cursor: pointer;
      opacity: 1;
    }
  }

  @media (min-width: 700px) {
    .current-image {
      padding: 150px 0 150px 0;
    }
  }
`;

class LightboxR extends React.Component {
  constructor() {
    super();
    this.state = {
      index: 0,
    };
  }

  componentDidMount() {
    this.highlightThumbnail(0);
  }

  prevClick = () => {
    let index =
      (this.state.index + this.props.images.length - 1) %
      this.props.images.length;
    this.highlightThumbnail(index);
    this.setState({ index });
  };

  nextClick = () => {
    let index =
      (this.state.index + this.props.images.length + 1) %
      this.props.images.length;
    this.highlightThumbnail(index);
    this.setState({ index });
  };

  thumbnailClick = (index) => {
    this.highlightThumbnail(index);
    this.setState({ index });
  };

  highlightThumbnail = (index) => {
    let thumbnails = document.querySelectorAll('.thumbnail_img');
    thumbnails[this.state.index].style.maxHeight = '80px';
    thumbnails[index].style.maxHeight = '100px';
  };

  render() {
    let imageList = [];
    let len = 100 / this.props.images.length;
    if (this.props.images !== null && this.props.images.length !== 0) {
      imageList = this.props.images.map((link, index) => {
        return (
          <div className='thumbnail-container' key={index}>
            <img
              key={index}
              className='thumbnail_img'
              src={link}
              alt=''
              onClick={() => this.thumbnailClick(index)}
            />
          </div>
        );
      });
    }

    return (
      <Modal show={true}>
        <Styles>
          <div className='lightbox-inner'>
            <img
              className='current-image'
              src={this.props.images[this.state.index]}
            />
          </div>
          <button
            type='button'
            className='ril-prev-button ril__navButtons ril__navButtonPrev'
            aria-label='Previous image'
            onClick={this.prevClick}
          ></button>
          <button
            type='button'
            className='ril-next-button ril__navButtons ril__navButtonNext'
            aria-label='Next image'
            onClick={this.nextClick}
          ></button>
          <div className='ril-toolbar ril__toolbar'>
            <ul className='ril-toolbar-left ril__toolbarSide ril__toolbarLeftSide'>
              <li className='ril-toolbar__item ril__toolbarItem'>
                <span className='ril-toolbar__item__child ril__toolbarItemChild'></span>
              </li>
            </ul>
            <ul className='ril-toolbar-right ril__toolbarSide ril__toolbarRightSide'>
              <li className='ril-toolbar__item ril__toolbarItem'>
                <button
                  type='button'
                  aria-label='Close lightbox'
                  className='ril-close ril-toolbar__item__child ril__toolbarItemChild ril__builtinButton ril__closeButton'
                  onClick={() => this.props.close()}
                ></button>
              </li>
            </ul>
          </div>
          <Container className='lightbox_preview'>
            <Row className='lightbox_row'>{imageList}</Row>
          </Container>
        </Styles>
      </Modal>
    );
  }
}

LightboxR.propTypes = {
  images: PropTypes.array,
  close: PropTypes.func,
};

export default LightboxR;
