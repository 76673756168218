import React from 'react';
import { Row, Col, Card, Image, Nav } from 'react-bootstrap';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { IEReplace } from '../components/IEReplace';
const logo = require('../assets/InPicturesLogo.png');
const photo = require('../assets/main-photo.jpg');
const video = require('../assets/main-video.jpg');

const Styles = styled.div`
  /*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v7.0.29,
* Autoprefixer: v9.7.6
* Browsers: last 4 version
*/

  padding-bottom: 40px;

  #intro {
    text-align: center;
    font-size: 4em;
    margin: -50px 0 50px 0;
  }

  .nav-link {
    color: #6a6a6a;
    font-size: 1.8rem;

    &:hover {
      color: black;
    }
  }

  .row {
    text-align: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .plus {
    font-size: 9em;
    font-weight: bold;
  }

  .card {
    text-align: center;
    overflow: hidden;
    border: 0px;
  }

  .card-text {
    margin-top: 0.75rem;
  }

  .side,
  .plus {
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }

  .card-body {
    z-index: 15;
    background-color: #fff;
  }

  .scale-on-hover {
    -webkit-transition: -webkit-transform 0.5s ease;
    transition: -webkit-transform 0.5s ease;
    -o-transition: transform 0.5s ease;
    transition: transform 0.5s ease;
    transition: transform 0.5s ease, -webkit-transform 0.5s ease;
    overflow: hidden;
    z-index: 5;
  }

  .plus,
  .homeCols {
    padding-left: 0px;
    padding-right: 0px;
  }

  .line {
    height: 1px;
    background-color: rgba(46, 46, 46, 0.5);
    margin-top: 5px;
    width: 100%;
    max-width: 100px;
    margin: auto;
  }

  @media (min-width: 576px) {
    .scale-on-hover:hover {
      cursor: pointer;
      -webkit-transform: scale(1.3);
      -ms-transform: scale(1.3);
      transform: scale(1.3);
      -webkit-box-shadow: 0 10px 10px rgba(0, 0, 0, 0.15) !important;
      box-shadow: 0 10px 10px rgba(0, 0, 0, 0.15) !important;
    }
  }

  #secondLogo {
    display: none;
  }

  @media (min-width: 2100px) {
    .frame {
      height: 500px;
    }
  }

  @media (max-width: 1080px) {
    #secondLogo {
      display: block;
      margin: auto;
      margin-bottom: 40px;
      margin-top: 40px;
    }

    #intro {
      margin: 20px 0 20px 0;
    }
  }
  @media (max-width: 767px) {
    #intro {
      margin: 20px 0 20px 0;
    }

    .plus,
    .homeCols {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
`;

function Home() {
  const history = useHistory();

  const toPortfolio = (loc) => {
    let path = '/portfolio/' + loc;
    history.push(path);
  };

  const IEdetection = () => {
    var ua = window.navigator.userAgent;
    var msie = ua.indexOf('MSIE ');
    if (msie > 0) {
      // IE 10 or older
      return true;
    }
    var trident = ua.indexOf('Trident/');
    if (trident > 0) {
      // IE 11
      return true;
    }

    return false;
  };

  return (
    <Styles>
      <Row>
        <Col>
          <h1 id='intro'>3 in 1</h1>
          <Image id='secondLogo' src={logo} rounded />
        </Col>
      </Row>
      <Row>
        <Col xs={12} lg={{ span: 3, order: 1 }} className='homeCols side'>
          <Card>
            <Card.Img
              className='scale-on-hover'
              variant='top'
              src={photo}
              onClick={() => toPortfolio('fotos')}
            />
          </Card>
        </Col>
        <Col xs={12} lg={{ span: 3, order: 6 }} className='homeCols'>
          <Card>
            <Card.Body>
              <Nav.Item>
                <Nav.Link href='/portfolio/fotos'>bedrijfs fotografie</Nav.Link>
              </Nav.Item>
              <div className='line' />
              <Card.Text>
                Professionele en vakkundige bedrijfsfotografie met een
                persoonlijke kijk op elke ruimte.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12} lg={{ span: 1, order: 2 }} className='plus'>
          +
        </Col>
        <Col xs={12} lg={{ span: 4, order: 3 }} className='homeCols'>
          <Card>
            {IEdetection() && <IEReplace />}
            {!IEdetection() && (
              <iframe
                src='https://my.matterport.com/show/?m=STc64myq1vH'
                allowFullScreen={true}
                frameBorder='0'
                scrolling='no'
                allow='vr;gyroscope;accelerometer'
                height='400'
                className='frame'
              ></iframe>
            )}
          </Card>
        </Col>
        <Col xs={12} lg={{ span: 4, order: 8 }} className='homeCols'>
          <Card>
            <Card.Body>
              <Nav.Item>
                <Nav.Link href='/portfolio/tours'>360⁰ Tours</Nav.Link>
              </Nav.Item>
              <div className='line' />
              <Card.Text>
                Een nieuwe methode om interieur te presenteren. Beleef de
                omgeving met gloednieuwe 360⁰ view.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12} lg={{ span: 1, order: 4 }} className='plus'>
          +
        </Col>
        <Col xs={12} lg={{ span: 3, order: 5 }} className='homeCols side'>
          <Card>
            <Card.Img
              className='scale-on-hover'
              variant='top'
              src={video}
              onClick={() => toPortfolio('videos')}
            />
          </Card>
        </Col>
        <Col xs={12} lg={{ span: 3, order: 10 }} className='homeCols'>
          <Card>
            <Card.Body>
              <Nav.Item>
                <Nav.Link href='/portfolio/videos'>Promotie Video</Nav.Link>
              </Nav.Item>
              <div className='line' />
              <Card.Text>
                Om relevant te blijven komt een promotie video op social media
                erg van pas. Met de huisstijl van het gebouw.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12} lg={{ span: 1, order: 7 }} className='plus'></Col>
        <Col xs={12} lg={{ span: 1, order: 9 }} className='plus'></Col>
      </Row>
    </Styles>
  );
}

export default Home;
