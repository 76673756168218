import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import {
  AiFillInstagram,
  AiFillFacebook,
  AiFillLinkedin,
} from 'react-icons/ai';

const Styles = styled.div`
  min-height: 40px;
  margin-bottom: 10px;
  margin-top: -50px;
  font-family: 'Bebas', sans-serif;

  a {
    color: #6a6a6a;
    &:hover {
      color: black;
    }
  }

  .container {
    text-align: center;
  }

  .line {
    width: 3px;
    height: auto;
    background-color: #2e2e2e;
    height: 100%;
  }

  .middle-col {
    border-left: 3px solid #222;
    border-right: 3px solid #222;
  }

  @media (max-width: 767px) {
    .middle-col {
      border: none;
    }
  }

  .icons {
    font-size: 25px;
  }

  .row {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
`;

export const FooterComponent = () => (
  <Styles>
    <Container>
      <Row>
        <Col md={3}>©2020 InPictures</Col>
        <Col md={2}>
          <div>Jesse Cohen</div>
        </Col>
        <Col className='middle-col' md={2}>
          <div> 06-14681094</div>
        </Col>
        <Col md={2}>
          <div>info@in-pictures.com</div>
        </Col>
        <Col className='icons' md={3}>
          <a
            href='https://www.instagram.com/inpictures.nl/?igshid=172czor27en0v'
            target='_blank'
            rel='noopener noreferrer'
            className='socialIcons'
          >
            <AiFillInstagram />
          </a>
          <a
            href='https://www.facebook.com/InPictures-114238576995521'
            target='_blank'
            rel='noopener noreferrer'
            className='socialIcons'
          >
            <AiFillFacebook />
          </a>
          <a
            href='https://www.linkedin.com/in/jesse-cohen-57a899195/'
            target='_blank'
            rel='noopener noreferrer'
            className='socialIcons'
          >
            <AiFillLinkedin />
          </a>
        </Col>
      </Row>
    </Container>
  </Styles>
);
