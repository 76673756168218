import * as firebase from 'firebase';
import emailjs from 'emailjs-com';

//config key firebase
const firebaseConfig = {
  apiKey: 'AIzaSyBe4YzbrXeNEAPlquVpREs6QlD9LycQDmg',
  authDomain: 'inpictures.firebaseapp.com',
  databaseURL: 'https://inpictures.firebaseio.com',
  projectId: 'inpictures',
  storageBucket: 'inpictures.appspot.com',
  messagingSenderId: '970963655026',
  appId: '1:970963655026:web:4bccb315997a2c10afe5eb',
  measurementId: 'G-ESZ9BE1M12',
};

//init firebaseApp
firebase.initializeApp(firebaseConfig);

//variables for storing values to be called inside sub functions
var storageRef = firebase.app().storage().ref('images');
var db = firebase.firestore();
var subList = [];
var listing = [];
var videoListing = [];
var finalVideoList = [];
var finalToursList = [];

//init emailJS
emailjs.init('user_BfglEu0fsUo2G7aRUkxDh');

//creates a array of the sub directories in the form of a reference
function subListing(subRef) {
  let ref = firebase.app().storage().ref(subRef.location.path_);
  subList.push(ref);
}

//obtains the storage references of the images inside all the sub-directories
async function getImages(subRef) {
  const dir = await subRef.listAll().catch(function (error) {
    // Uh-oh, an error occurred!
    console.log('some error');
    console.log(error);
  });

  let sublisting = [];
  dir.items.forEach(function (item) {
    sublisting.push(item.location.path_);
  });
  if (sublisting.length > 0) {
    listing.push(sublisting);
  }
}

//uses provided list of storage references to return the url of all images in every sub-directory
export async function getImagesUrl(list) {
  let urlList = [];
  for (let i = 0; i < list.length; i++) {
    let storageRef = firebase.app().storage().ref(list[i]);
    let url = await storageRef.getDownloadURL().catch(function (error) {
      console.log(error.code);
      // A full list of error codes is available at
      // https://firebase.google.com/docs/storage/web/handle-errors
      switch (error.code) {
        case 'storage/object-not-found':
          // File doesn't exist
          break;

        case 'storage/unauthorized':
          // User doesn't have permission to access the object
          break;

        case 'storage/canceled':
          // User canceled the upload
          break;

        case 'storage/unknown':
          // Unknown error occurred, inspect the server response
          break;

        default:
          break;
      }
    });
    urlList.push(url);
  }
  return urlList;
}

export function amountOfImagePages() {
  return Math.ceil(listing.length / 30);
}

//returns a list of the storage references for all images in every sub-directory in sub arrays
export async function getImagesList() {
  if (listing.length === 0) {
    //get list of sub collections
    const subs = await storageRef.listAll();
    //make list of subcollections
    subs.prefixes.forEach(subListing);
    //populate list with refs
    for (let i = 0; i < subList.length; i++) {
      await getImages(subList[i]);
    }
  }

  return listing;
}

//obtains the storage references of the images inside all the sub-directories
function getVideosList(dir) {
  dir.items.forEach(function (item) {
    videoListing.push(item.location.path_);
  });
}

export async function getVideos() {
  if (finalVideoList.length === 0) {
    storageRef = firebase.app().storage().ref('videos');
    const vidSubs = await storageRef.listAll();
    getVideosList(vidSubs);
    finalVideoList = await getImagesUrl(videoListing);
  }
  return finalVideoList;
}

export async function getTours() {
  if (finalToursList.length === 0) {
    await db
      .collection('tours')
      .get()
      .then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
          // doc.data() is never undefined for query doc snapshots
          finalToursList.push(doc.data());
        });
      });
  }
  return finalToursList;
}

export async function formSubmit(state) {
  let result = await emailjs.send('default_service', 'webform', {
    name: state.name,
    email: state.email,
    message: state.message,
    phone: state.phone,
    package: state.pkg,
    rooms: state.rooms,
  });
  return result;
}
