import React from 'react';
import { Nav, Navbar, Image } from 'react-bootstrap';
import styled from 'styled-components';

const logo = require('../assets/InPicturesLogo.png');

const Styles = styled.div`
  .navbar-toggler {
    color: white;
  }

  .nav-item {
    text-align: right;
  }

  .navbar {
    background-color: white;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }

  #secondside {
    visibility: hidden;
  }

  .nav-link {
    margin: 0 10px 0 10px;
  }

  #basic-navbar-nav {
    -ms-flex-item-align: start;
    align-self: flex-start;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding-top: 20px;
  }

  .navbar-nav .nav-link {
    color: #6a6a6a;
    text-transform: uppercase;
    font-size: 1.6rem;

    &:hover {
      color: black;
    }
  }

  .line {
    width: 3px;
    height: auto;
    background-color: #2e2e2e;
    margin: 0 20px 0 20px;
  }

  @media (max-width: 1080px) {
    .navbar-brand {
      display: none;
    }
  }

  .icon-bar {
    width: 29px;
    height: 3px;
    margin: 5px 0 5px 0;
    display: inherit;
    background-color: #6a6a6a;
    -webkit-transition: all 0.2s;
    -o-transition: all 0.2s;
    transition: all 0.2s;
  }

  /* ANIMATED X */
  .navbar-toggler .icon-bar:nth-of-type(1) {
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    -webkit-transform-origin: 10% 10%;
    -ms-transform-origin: 10% 10%;
    transform-origin: 10% 10%;
  }

  .navbar-toggler .icon-bar:nth-of-type(2) {
    opacity: 0;
    filter: alpha(opacity=0);
  }

  .navbar-toggler .icon-bar:nth-of-type(3) {
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
    -webkit-transform-origin: 10% 90%;
    -ms-transform-origin: 10% 90%;
    transform-origin: 10% 90%;
  }

  /* ANIMATED X COLLAPSED */
  .navbar-toggler.collapsed .icon-bar:nth-of-type(1) {
    -webkit-transform: rotate(0);
    -ms-transform: rotate(0);
    transform: rotate(0);
  }

  .navbar-toggler.collapsed .icon-bar:nth-of-type(2) {
    opacity: 1;
    filter: alpha(opacity=100);
  }

  .navbar-toggler.collapsed .icon-bar:nth-of-type(3) {
    -webkit-transform: rotate(0);
    -ms-transform: rotate(0);
    transform: rotate(0);
  }

  .navbar-toggler.collapsed {
    border-color: rgba(0, 0, 0, 0.1);
  }

  .navbar-toggler {
    border-color: #fff;

    &:hover {
      .icon-bar {
        background-color: black;
      }
    }
  }
  /* END ANIMATED X */
`;

class NavbarComponent extends React.Component {
  render() {
    return (
      <Styles>
        <Navbar expand='lg' collapseOnSelect onToggle={this.toggler}>
          <Navbar.Brand href='/'>
            <Image src={logo} rounded />
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls='basic-navbar-nav'
            className='navbar-toggler'
            data-toggle='collapse'
            data-target='#navbar-collapse-x'
          >
            <span className='icon-bar'></span>
            <span className='icon-bar'></span>
            <span className='icon-bar'></span>
          </Navbar.Toggle>
          <Navbar.Collapse id='basic-navbar-nav'>
            <Nav>
              <Nav.Item>
                <Nav.Link href='/home'>Home</Nav.Link>
              </Nav.Item>
              <div className='line'></div>
              <Nav.Item>
                <Nav.Link href='/portfolio'>Portfolio</Nav.Link>
              </Nav.Item>
              <div className='line'></div>
              <Nav.Item>
                <Nav.Link href='/info'>Info</Nav.Link>
              </Nav.Item>
              <div className='line'></div>
              <Nav.Item>
                <Nav.Link href='/pricing'>Prijzen</Nav.Link>
              </Nav.Item>
              <div className='line'></div>
              <Nav.Item>
                <Nav.Link href='/contact'>Contact</Nav.Link>
              </Nav.Item>
            </Nav>
          </Navbar.Collapse>
          <Navbar.Brand href='/' id='secondside'>
            <Image src={logo} rounded />
          </Navbar.Brand>
        </Navbar>
      </Styles>
    );
  }
}

export default NavbarComponent;
