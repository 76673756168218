import React from 'react';
import styled from 'styled-components';
import Loader from 'react-loader-spinner';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { getVideos } from '../services/service';
const logo = require('../assets/thumbnail.png');

const Styles = styled.div`
  video {
    max-width: 80%;
  }

  .videoContainer {
    max-width: 100%;
    text-align: center;
    margin: auto;
  }

  .buttonsContainer {
    padding: 0px;
    margin: auto;
    padding-top: 30px;
    max-width: 80%;
  }

  .btn-dark {
    display: block;
    color: #fff;
    background-color: black;
    font-size: 20px;
    margin: auto;
    border: 2px solid black;
    padding: 10px;

    &:hover {
      text-decoration: none;
      color: black;
      background-color: #fff;
    }
  }

  #pageSpan {
    position: absolute;
    bottom: 0;
  }

  #pages {
    text-align: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  @media (max-width: 766px) {
    video {
      max-width: 100%;
    }
  }

  @media (max-width: 1080px) {
    video {
      margin-top: 30px;
    }
  }
`;

class PortfolioVideos extends React.Component {
  constructor(props) {
    super(props);
    this.next = this.next.bind(this);
    this.prev = this.prev.bind(this);
    this.loadVideos = this.loadVideos.bind(this);
    this.videoList = [];
    this.pages = 0;
    this.loaded = false;
    this._mounted = false;
    this.state = {
      index: 0,
      loading: true,
      error: false,
      videos: [],
      pageIndex: 1,
      showNext: false,
      showPrev: false,
    };
  }

  componentDidMount() {
    this._mounted = true;
    this.loadVideos();
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  async loadVideos() {
    let list = await getVideos();
    if(list.length === 0) {
      this.setState({ error: true });
    } else {
      this.pages = list.length;
      let showNext = false;
      if (list.length > 1) {
        showNext = true;
      }
      const videoContainer = list.map((url, index) => {
        return (
          <video controls poster={logo}>
            <source src={url} type='video/mp4' key={index} />
            Your browser does not support the video tag used to
          </video>
        );
      });
      if (this._mounted) {
        this.setState(
          { videos: videoContainer, loading: false, showNext },
          function () {
            this.loaded = true;
          }
        );
      }
    }
  }

  next() {
    let newI = this.state.index + 1;
    let pageIndex = this.state.pageIndex;
    let showPrev = true;
    let showNext = true;

    if (newI >= this.state.videos.length - 1) {
      newI = this.state.videos.length - 1;
      showNext = false;
    }

    pageIndex = this.state.pageIndex + 1;

    this.setState(
      { loading: true, index: newI, pageIndex: pageIndex },
      function () {
        this.setState({ index: newI, loading: false, showNext, showPrev });
      }
    );
  }

  prev() {
    let newI = this.state.index - 1;
    let pageIndex = newI + 1;
    let showNext = true;
    let showPrev = true;
    
    if (newI <= 0) {
      newI = 0;
      showPrev = false;
    }

    this.setState(
      { loading: true, index: newI, pageIndex: pageIndex },
      function () {
        this.setState({ loading: false, showNext, showPrev });
      }
    );
  }

  render() {
    var loadingCheck = {
      visibility: 'visible',
      textAlign: 'center',
    };
    return (
      <Styles>
        {this.state.loading && (
          <div style={loadingCheck}>
            <h1>Loading</h1>
            <Loader type='ThreeDots' color='#222' height='100' width='100' />
          </div>
        )}
        {this.state.error && (
          <div style={loadingCheck}>
            <h1>whoops, er is iets verkeerd gegaan</h1>
            <p>
              Herlaad de pagina, help dit niet? neem dam contact met mij op!
            </p>
          </div>
        )}
        {!this.state.loading && (
          <div>
            <div className='videoContainer'>
              {this.state.videos[this.state.index]}
            </div>
            <Container className='buttonsContainer'>
              <Row>
                <Col>
                  {this.state.showPrev && (
                    <Button variant='dark' onClick={this.prev} id='prev'>
                      {'<'}
                    </Button>
                  )}
                </Col>
                <Col id='pages'>
                  <span id='pageSpan'>
                    {this.state.pageIndex}/{this.pages}
                  </span>
                </Col>
                <Col>
                  {this.state.showNext && (
                    <Button variant='dark' onClick={this.next} id='next'>
                      {'>'}
                    </Button>
                  )}
                </Col>
              </Row>
            </Container>
          </div>
        )}
      </Styles>
    );
  }
}

export default PortfolioVideos;
