import React from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import styled from 'styled-components';
import {
  AiFillInstagram,
  AiFillFacebook,
  AiFillLinkedin,
} from 'react-icons/ai';

var jesse = require('../assets/jesse.png');

const Styles = styled.div`
  margin-top: 30px;
  .card-img {
    max-height: 250px;
    max-width: 250px;
    padding: 20px;
    margin: auto;
  }

  .text-col {
    text-align: left;
    margin-bottom: 40px;
  }

  .card {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    max-width: 900px;
    margin: auto;
    margin-bottom: 60px;
  }

  .card-footer {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    background-color: #2e2e2e;
    color: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: space-evenly;
    -ms-flex-pack: space-evenly;
    justify-content: space-evenly;
    font-size: 30px;
  }

  .card-title {
    font-size: 40px;
  }

  .break {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    height: 0;
  }

  .line {
    height: 2px;
    background-color: #2e2e2e;
    width: 100%;
    margin-bottom: 10px;
  }

  .above-line {
    margin-bottom: 5px;
    font-style: italic;
    color: #2e2e2e;
  }

  .second-td {
    padding-left: 25px;
  }

  a {
    color: #fff;

    &:hover {
      color: #6a6a6a;
    }
  }

  @media (min-width: 992px) {
  }

  @media (min-width: 1080px) {
    margin-top: 0px;
  }
`;

function Info() {
  return (
    <Styles>
      <Row
        style={{ justifyContent: 'center', maxWidth: '900px', margin: 'auto' }}
      >
        <Col className='text-col' lg={6}>
          <p>
            De mate van succes wordt vandaag de dag bepaald hoe men zich
            profileert online.
            <br />
            <br />
            Sfeer, kwaliteit en duidelijkheid zijn verreist in het hedendaagse
            beeld hoe men een huis, kantoor, kamer of hotel bekijken. De
            concurrentiestrijd wordt vandaag de dag bevochten online. Hoe
            duidelijker men zich daar presenteert wordt direct gekoppeld aan de
            slagingskans.
            <br />
            <br />
          </p>
        </Col>
        <Col className='text-col' lg={6}>
          <p>
            Online fotografie, social media, bewegend beeld, 360 graden tours
            kunnen een totale kwaliteitsweergave geven van uw bedrijf. Door de
            duidelijkheid hiervan wordt de slagingskans op de aanhuur
            aanzienlijk hoger. Dit gaat de nieuwe norm worden dus wees er per
            tijds bij. "what you see is what you get" wordt letterlijk genomen.
            <br />
            <br />
            Dat is waar InPictures u in kan helpen. Het verschil tussen platte
            fotografie dat is waar InPictures voor opgericht is. Dat is mijn
            missie, alsof je er zelf bent. Daar ga ik het verschil voor u maken.
          </p>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <Card.Img src={jesse} />
            <Card.Body>
              <Card.Title>Jesse Cohen</Card.Title>
              <p className='above-line'>Fotograaf, editor en vormgever</p>
              <div className='line'></div>
              <table>
                <tbody>
                  <tr>
                    <td>email</td>
                    <td className='second-td'>info@in-pictures.com</td>
                  </tr>
                  <tr>
                    <td>Tel</td>
                    <td className='second-td'>06-14681094</td>
                  </tr>
                </tbody>
              </table>
            </Card.Body>
            <div className='break'></div>
            <Card.Footer>
              <a
                href='https://www.instagram.com/inpictures.nl/?igshid=172czor27en0v'
                target='_blank'
                rel='noopener noreferrer'
              >
                <AiFillInstagram />
              </a>
              <a
                href='https://www.facebook.com/InPictures-114238576995521'
                target='_blank'
                rel='noopener noreferrer'
              >
                <AiFillFacebook />
              </a>
              <a
                href='https://www.linkedin.com/in/jesse-cohen-57a899195/'
                target='_blank'
                rel='noopener noreferrer'
              >
                <AiFillLinkedin />
              </a>
            </Card.Footer>
          </Card>
        </Col>
      </Row>
    </Styles>
  );
}

export default Info;
