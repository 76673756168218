import React from 'react';
import styled from 'styled-components';
import Loader from 'react-loader-spinner';
import { IEReplace } from '../components/IEReplace';
import { getTours } from '../services/service';
import { Container, Row, Col, Button } from 'react-bootstrap';

const Styles = styled.div`
  #btnContainer {
    padding-top: 20px;
  }

  .frameContainer {
    max-width: 100%;
    text-align: center;
    margin: auto;
  }

  iframe {
    max-width: 80%;
  }

  .btn-dark {
    display: block;
    color: #fff;
    background-color: black;
    font-size: 20px;
    margin: auto;
    border: 2px solid black;

    &:hover {
      text-decoration: none;
      color: black;
      background-color: #fff;
    }
  }

  button {
    padding: 10px 10px 10px 10px !important;
  }

  #pages {
    text-align: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  #pageSpan {
    position: absolute;
    bottom: 0;
  }
`;

class Portfolio360 extends React.Component {
  constructor(props) {
    super(props);
    this.pages = 1;
    this.toursList = [];
    this._mounted = false;
    this.loadTours = this.loadTours.bind(this);
    this.next = this.next.bind(this);
    this.prev = this.prev.bind(this);
    this.state = {
      loading: true,
      error: false,
      tours: [],
      pageIndex: 1,
      index: 0,
      showPrev: false,
      showNext: true,
    };
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  componentDidMount() {
    this._mounted = true;
    this.loadTours();
  }

  next() {
    let newI = this.state.index + 1;
    let newPageIndex = this.state.pageIndex + 1;
    let show = true;
    if (newI >= this.state.tours.length - 1) {
      newI = this.state.tours.length - 1;
      newPageIndex = this.state.pageIndex + 1;
      show = false;
    }

    this.setState(
      { loading: true, showNext: show, showPrev: true },
      function () {
        this.setState({ index: newI, pageIndex: newPageIndex, loading: false });
      }
    );
  }

  prev() {
    let newI = this.state.index - 1;
    let newPageIndex = this.state.pageIndex - 1;
    let show = true;
    if (newI <= 0) {
      newI = 0;
      show = false;
    }

    this.setState(
      { loading: true, showNext: true, showPrev: show },
      function () {
        this.setState({ index: newI, pageIndex: newPageIndex, loading: false });
      }
    );
  }

  async loadTours() {
    this.toursList = await getTours();

    if(this.toursList.length === 0) {
      this.setState({error: true});
    } else {
      this.pages = Math.ceil(this.toursList.length);

      const sublist = this.toursList.map((obj, index) => {
        let style = { width: '100%', border: 'none' };
        return (
          <iframe
            src={obj.src}
            key={index}
            title={obj.naam}
            allowFullScreen={true}
            frameBorder='0'
            scrolling='no'
            height='550'
            style={style}
          ></iframe>
        );
      });

      this.setState({ tours: sublist, loading: false });
    }
  }

  IEdetection = () => { 
    var ua = window.navigator.userAgent; 
    var msie = ua.indexOf('MSIE '); 
    if (msie > 0) { 
      // IE 10 or older
      return true;
      } 
      var trident = ua.indexOf('Trident/'); 
      if (trident > 0) { 
      // IE 11
      return true;
      }

      return false;
    }

  render() {
    var loadingCheck = {
      textAlign: 'center',
    };
    return (
      <div>
        {this.state.loading && (
          <div style={loadingCheck}>
            <h1>Loading</h1>
            <Loader type='ThreeDots' color='#222' height='100' width='100' />
          </div>
        )}
        {this.state.error && (
          <div style={loadingCheck}>
            <h1>whoops, er is iets verkeerd gegaan</h1>
            <p>
              Herlaad de pagina, help dit niet? neem dam contact met mij op!
            </p>
          </div>
        )}
        {!this.state.loading && (
          <Styles>
            {this.IEdetection() && <IEReplace />}
            {!this.IEdetection() && (
              <div className='frameContainer'>
                {this.state.tours[this.state.index]}
              </div>
            )}
            <Container id='btnContainer'>
              <Row>
                <Col>
                  {this.state.showPrev && (
                    <Button variant='dark' onClick={this.prev} id='prev'>
                      {'<'}
                    </Button>
                  )}
                </Col>
                <Col id='pages'>
                  <span id='pageSpan'>
                    {this.state.pageIndex}/{this.pages}
                  </span>
                </Col>
                <Col>
                  {this.state.showNext && (
                    <Button variant='dark' onClick={this.next} id='next'>
                      {'>'}
                    </Button>
                  )}
                </Col>
              </Row>
            </Container>
          </Styles>
        )}
      </div>
    );
  }
}

export default Portfolio360;
