import React from 'react';
import {
  Card,
  CardDeck,
  Col,
  Row,
  Container,
  Nav,
} from 'react-bootstrap';
import styled from 'styled-components';

const Styles = styled.div`
  margin-top: 30px;

  .card {
    text-align: center;
    margin: 30px 0 30px 0;
  }

  .nav-link {
    -ms-flex-item-align: end;
    align-self: flex-end;
    text-transform: uppercase;
    background-color: #222;
    color: #fff;
    border: 3px solid #fff;
    max-width: 70%;
    margin: auto;
    font-weight: 400;
    font-size: 1rem;
    border-radius: 0.25rem;
    font-size: 1.2rem;

    :hover {
      background-color: #fff !important;
      color: #222;
      border: 3px solid #222;
    }
  }

  .card-header {
    background-color: #222;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 2.75rem 1.25rem;
  }

  .card-body {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .card-footer {
    background-color: #fff;
    border-top: 0px;
  }

  .price {
    font-size: 40px;
    -webkit-text-decoration: bold;
    text-decoration: bold;
    color: #fff;
  }

  .leauge {
    -webkit-text-decoration: bold;
    text-decoration: bold;
  }

  #bronze {
    color: #cd7f32;
  }

  #silver {
    color: #c0c0c0;
  }

  #gold {
    color: #ffd700;
  }

  @media (min-width: 576px) {
    .card {
      min-height: 500px;
    }

    .card-header {
      min-height: 200px;
    }
  }
`;

function Pricing() {
  return (
    <Styles>
      <CardDeck>
        <Container>
          <Row>
            <Col md={12} lg={4}>
              <Card>
                <Card.Header>
                  <span className='price'>€399,99</span>
                  <br />
                  <span className='leauge' id='bronze'>
                    Brons pakket
                  </span>
                </Card.Header>
                <Card.Body>
                  <Card.Text>
                    1-5 ruimtes
                    <br />
                    <br />
                    Volledige pakket (Foto, video, 360°)
                  </Card.Text>
                </Card.Body>
                <Card.Footer>
                      <Nav.Link href='/contact'>Vraag aan</Nav.Link>
                </Card.Footer>
              </Card>
            </Col>
            <Col md={12} lg={4}>
              <Card>
                <Card.Header>
                  <span className='price'>€499,99</span>
                  <br />
                  <span className='leauge' id='silver'>
                    Zilver pakket
                  </span>
                </Card.Header>
                <Card.Body>
                  <Card.Text>
                    6-10 ruimtes
                    <br />
                    <br />
                    Volledige pakket (Foto, video, 360°)
                  </Card.Text>
                </Card.Body>
                <Card.Footer>
                      <Nav.Link href='/contact'>Vraag aan</Nav.Link>
                </Card.Footer>
              </Card>
            </Col>
            <Col md={12} lg={4}>
              <Card>
                <Card.Header>
                  <span className='price'>€599,99</span>
                  <br />
                  <span className='leauge' id='gold'>
                    Goud pakket
                  </span>
                </Card.Header>
                <Card.Body>
                  <Card.Text>
                    11-20 ruimtes
                    <br />
                    <br />
                    Volledige pakket (Foto, video, 360°)
                  </Card.Text>
                </Card.Body>
                <Card.Footer>
                      <Nav.Link href='/contact'>Vraag aan</Nav.Link>
                </Card.Footer>
              </Card>
            </Col>
          </Row>
        </Container>
      </CardDeck>
    </Styles>
  );
}

export default Pricing;
