import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import styled from 'styled-components';
import { formSubmit } from '../services/service';

const Styles = styled.div`
  .sendRow {
    text-align: center;
    margin-top: 30px;
  }

  .btn {
    -ms-flex-item-align: end;
    align-self: flex-end;
    text-transform: uppercase;
    background-color: #222;
    border-color: #222;

    :hover {
      background-color: #fff;
      color: #222;
    }
  }

  .success {
    text-align: center;
    font-size: 25px;
    color: green;
  }

  .fail {
    text-align: center;
    font-size: 25px;
    color: red;
  }

  .form-group {
    text-align: end;
  }

  @media (max-width: 767px) {
    .form-group {
      text-align: center;
    }
  }
`;

class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleDropdown = this.handleDropdown.bind(this);
    this.state = {
      name: '',
      email: '',
      phone: '',
      pkg: '',
      rooms: '',
      message: '',
      mailSent: false,
      error: false,
    };
  }

  handleDropdown(e) {
    this.setState({ pkg: e.target.value });
  }

  async handleFormSubmit(e) {
    e.preventDefault();
    let result = await formSubmit(this.state);
    console.log(result);
    if (result.status === 200) {
      this.setState({ mailSent: true });
    } else {
      this.setState({ error: true });
    }
  }

  render() {
    return (
      <Container>
        <form id='webform' onSubmit={this.handleFormSubmit}>
          <Row>
            <Col md={6}>
              <div className='form-group'>
                <label>Naam</label>
                <input
                  className='form-control item'
                  type='text'
                  id='naam'
                  name='name'
                  required
                  onChange={(e) => this.setState({ name: e.target.value })}
                  value={this.state.name}
                />
              </div>
              <div className='form-group'>
                <label>Email</label>
                <input
                  className='form-control item'
                  type='email'
                  id='email'
                  name='email'
                  required
                  onChange={(e) => this.setState({ email: e.target.value })}
                  value={this.state.email}
                />
              </div>
              <div className='form-group'>
                <label>Telefoonnummer</label>
                <input
                  className='form-control item'
                  type='text'
                  id='tel'
                  required
                  onChange={(e) => this.setState({ phone: e.target.value })}
                  value={this.state.phone}
                />
              </div>
            </Col>
            <Col md={6}>
              <div className='form-group'>
                <label>Pakket</label>
                <select
                  className='form-control item'
                  type='select'
                  id='pakket'
                  value={this.state.pkg}
                  onChange={this.handleDropdown}
                >
                  <option value='Bronze'>Brons</option>
                  <option value='Silver'>Zilver</option>
                  <option value='Goud'>Goud</option>
                </select>
              </div>
              <div className='form-group'>
                <label>Aantal Ruimtes</label>
                <input
                  className='form-control item'
                  type='number'
                  id='ruimtes'
                  required
                  onChange={(e) => this.setState({ rooms: e.target.value })}
                  value={this.state.rooms}
                />
              </div>
              <div className='form-group'>
                <label>Bericht</label>
                <textarea
                  className='form-control item'
                  id='bericht'
                  name='message'
                  required
                  onChange={(e) => this.setState({ message: e.target.value })}
                  value={this.state.message}
                ></textarea>
              </div>
            </Col>
          </Row>
          <Styles>
            <Row className='sendRow'>
              <Col>
                <div className='form-group'>
                  <Button className='sendBtn' type='submit' value='Submit'>
                    Verstuur
                  </Button>
                </div>
              </Col>
            </Row>
          </Styles>
        </form>
        {this.state.mailSent && (
          <Styles>
            <Row>
              <Col>
                <div>
                  <p className='success'>Uw bericht is verzonden</p>
                </div>
              </Col>
            </Row>
          </Styles>
        )}
        {this.state.error && (
          <Styles>
            <Row>
              <Col>
                <div>
                  <p className='fail'>
                    Probleem bij het verzenden van uw bericht.
                    <br />
                    Gelieven verwijzen we uw naar de info pagina voor contact
                    details.
                    <br />
                    Onze excuses voor het ongemak
                  </p>
                </div>
              </Col>
            </Row>
          </Styles>
        )}
      </Container>
    );
  }
}

export default Contact;
