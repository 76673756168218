import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import PortfolioImages from '../components/PortfolioImages';
import PortfolioVideos from '../components/PortfolioVideos';
import Portfolio360 from '../components/Portfolio360';

const Styles = styled.div`
  .sidebar {
    margin-top: 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .portfolioCol {
    max-width: 100%;
  }

  .sidebarBtn {
    -ms-flex-item-align: end;

    align-self: flex-end;
  }

  .sidebar-col {
    padding-left: 0px;
  }

  button {
    display: block;
    background-color: transparent;
    border: none;
    color: #6a6a6a;
    font-size: 20px;
    padding-left: 0px;
    font-size: 1.5rem;

    &:hover {
      text-decoration: none;
      color: black;
    }
  }

  .list-unstyled,
  .heading {
    padding-left: 20px;
  }

  @media (max-width: 1080px) {
    margin-top: 50px;
  }
`;

class Portfolio extends React.Component {
  constructor(props) {
    super(props);
    this.handler = 'none';
    this.handleClickFoto = this.handleClickFoto.bind(this);
    this.handleClickVideo = this.handleClickVideo.bind(this);
    this.handleClickDeg = this.handleClickDeg.bind(this);
    this.state = { current: 'none' };
  }

  componentWillMount() {
    console.log(this.handler);
    if (
      this.props.location.pathname === '/portfolio/fotos' ||
      this.props.location.pathname === '/portfolio'
    ) {
      this.handler = 'fotos';
    } else if (this.props.location.pathname === '/portfolio/videos') {
      this.handler = 'videos';
    } else if (this.props.location.pathname === '/portfolio/tours') {
      this.handler = 'tours';
    }
  }

  handleClickFoto() {
    window.history.replaceState(null, 'InPictures', '/portfolio/fotos');
    this.setState({ current: 'fotos' });
  }

  handleClickVideo() {
    window.history.replaceState(null, 'InPictures', '/portfolio/videos');
    this.setState({ current: 'videos' });
  }

  handleClickDeg() {
    window.history.replaceState(null, 'InPictures', '/portfolio/tours');
    this.setState({ current: 'tours' });
  }

  render() {
    if(this.state.current === "none") {
      console.log(this.handler);
    } else {
      this.handler = this.state.current;
    }
    
    return (
      <Styles>
        <Container className='portfolioCol'>
          <Row>
            <Col md={3} className='sidebar-col'>
              <ul className='list-unstyled sidebar'>
                <button className='sidebarBtn' onClick={this.handleClickFoto}>
                  Fotos
                </button>
                <button className='sidebarBtn' onClick={this.handleClickVideo}>
                  Videos
                </button>
                <button className='sidebarBtn' onClick={this.handleClickDeg}>
                  360⁰ tours
                </button>
              </ul>
            </Col>
            <Col md={9}>
              {this.handler === 'fotos' && <PortfolioImages />}
              {this.handler === 'videos' && <PortfolioVideos />}
              {this.handler === 'tours' && <Portfolio360 />}
            </Col>
          </Row>
        </Container>
      </Styles>
    );
  }
}

export default Portfolio;
