import React from 'react';
import { Container } from 'react-bootstrap';
import styled from 'styled-components';

const Styles = styled.div`
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  padding-bottom: 60px;

  @media (min-width: 992px) {
    .main-container {
      max-width: 100%;
      padding-left: 15px;
      padding-right: 15px;
    }
  }
`;

export const Layout = (props) => (
  <Styles>
    <Container className='main-container'>{props.children}</Container>
  </Styles>
);
