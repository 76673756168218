import React from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import Route from './routes/Route';
import './App.css';
import 'react-image-lightbox/style.css';

import Home from './pages/Home';
//import Login from './pages/Login';
import Portfolio from './pages/Portfolio';
import Contact from './pages/Contact';
import Pricing from './pages/Pricing';
import Info from './pages/Info';
import NavbarComponent from './components/NavbarComponent';
import { Layout } from './components/Layout';
import { FooterComponent } from './components/FooterComponent';

function App() {
  return (
    <React.Fragment>
      <div className='site-container'>
        <Layout>
          <NavbarComponent />
          <Router>
            <Switch>
              <Route path='/' exact component={Home} />
              <Route path='/portfolio' component={Portfolio} />
              <Route path='/contact' component={Contact} />
              <Route path='/pricing' component={Pricing} />
              <Route path='/info' component={Info} />
              <Route component={Home} />
            </Switch>
          </Router>
        </Layout>
      </div>
      <FooterComponent />
    </React.Fragment>
  );
}

export default App;
