import React from 'react';
import styled from 'styled-components';

const Styles = styled.div`
  height: 400;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
`;

export const IEReplace = () => (
  <Styles>
    <h4>Helaas worden de 360⁰ tours niet ondersteund in Internet Explorer.
      <br /><br />Voor een betere ervaring wordt aangeraden om een andere browser te gebruiken</h4>
  </Styles>
);