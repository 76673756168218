import React from 'react';
import styled from 'styled-components';
import Loader from 'react-loader-spinner';
import { Container, Row, Col, Button } from 'react-bootstrap';
import LightboxR from '../components/Lightbox';
import {
  getImagesList,
  getImagesUrl,
  amountOfImagePages,
} from '../services/service';

import Masonry from 'react-masonry-css';

const Styles = styled.div`
  min-height: 101vh;

  .image {
    &:hover {
      cursor: pointer;
    }
  }

  .grid-item.project-sidebar-card {
    margin: 5px 2.5px 2.5px 2.5px;
  }

  #btnContainer {
    padding-top: 20px;
  }

  .btn-dark {
    display: block;
    color: #fff;
    background-color: black;
    font-size: 20px;
    margin: auto;
    border: 2px solid black;

    &:hover {
      text-decoration: none;
      color: black;
      background-color: #fff;
    }
  }

  .loadingContainer {
    text-align: center;
    background-color: purple;
  }

  .project-sidebar-card {
    overflow: hidden;
  }

  a {
    overflow: hidden;
  }

  button {
    padding: 10px 10px 10px 10px !important;
  }

  #pages {
    text-align: center;
    visibility: hidden;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  #pageSpan {
    position: absolute;
    bottom: 0;
  }

  .scale-on-hover {
    -webkit-transition: -webkit-transform 0.5s ease;
    transition: -webkit-transform 0.5s ease;
    -o-transition: transform 0.5s ease;
    transition: transform 0.5s ease;
    transition: transform 0.5s ease, -webkit-transform 0.5s ease;
    overflow: hidden;
  }

  .big-img,
  .small-img {
    height: auto;
  }

  .test {
    min-width: 300px;
    min-height: 300px;
    background-color: black;
  }

  @media (min-width: 576px) {
    .scale-on-hover:hover {
      -webkit-transform: scale(1.3);
      -ms-transform: scale(1.3);
      transform: scale(1.3);
    }
  }

  @media (min-width: 720px) {
    .big-img {
      height: 13rem;
    }

    .small-img {
      height: 9rem;
    }
  }

  @media (min-width: 990px) {
    .big-img {
      height: 17rem;
    }

    .small-img {
      height: 13rem;
    }
  }

  @media (min-width: 1180px) {
    .big-img {
      height: 23rem;
    }

    .small-img {
      height: 19rem;
    }
  }

  @media (min-width: 2000px) {
    .big-img {
      height: 30rem;
    }

    .small-img {
      height: 26rem;
    }
  }
`;

class PortfolioImages extends React.Component {
  constructor() {
    super();
    this.loadSize = 30;
    this.currentSize = 0;
    this.imagesLoaded = 0;
    this.imagesList = [];
    this.stack = [];
    this.lightbox = [];
    this.pages = 0;
    this.imageLoaded = this.imageLoaded.bind(this);
    this.next = this.next.bind(this);
    this.prev = this.prev.bind(this);
    this._mounted = false;
    this.imagesLeft = false;

    this.state = {
      loading: true,
      error: false,
      images: [],
      index: 0,
      pageIndex: 1,
      photoIndex: 0,
      isOpen: false,
      showNext: false,
      showPrev: false,
    };
  }

  componentDidMount() {
    this._mounted = true;
    this.init();
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  //move to next slide (page)
  next() {
    let newI = this.state.index + 30;
    let pageIndex = this.state.pageIndex + 1;

    this.imagesLoaded = 0;

    this.setState({ index: newI, loading: true, pageIndex: pageIndex }, () => {
      var row = document.querySelector('.loaderRow');
      row.style.visibility = 'hidden';
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
      if (newI >= this.stack.length) {
        this.loadImages();
      } else {
        //pass true for next 30, and flase for prev 30
        this.loadFromStack(true);
      }
    });
  }

  //move to previous slide (page)
  prev() {
    let pageIndex = this.state.pageIndex - 1;
    let newI = this.state.index - 30;

    if (newI < 0) {
      newI = 0;
    }

    this.imagesLoaded = 0;
    //after state changed, scroll back to the top of te page
    this.setState({ index: newI, pageIndex: pageIndex }, () => {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
      //passing false for prev 30
      this.loadFromStack(false);
    });
  }

  randomIntFromInterval(min, max) {
    // min and max included
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  async init() {
    this.imagesList = await getImagesList();
    //check for empty array
    if (this.imagesList.length === 0) {
      //something went wrong
      this.setState({ error: true });
    } else {
      this.pages = amountOfImagePages();
      this.loadImages();
    }
  }

  loadFromStack = (s) => {
    let preList = [];
    let showNext = true;
    let showPrev = true;

    if (s) {
      //next
      if (this.state.index + 30 < this.stack.length) {
        preList = this.stack.slice(this.state.index, this.state.index + 30);
      } else {
        preList = this.stack.slice(this.state.index, this.stack.length);
        this.loadSize = preList.length;
        showNext = false;
      }
    } else {
      //prev
      preList = this.stack.slice(this.state.index, this.state.index + 30);
      if (this.state.index === 0) {
        showPrev = false;
      }
    }

    this.setState({ images: preList, showNext, showPrev });
  };

  async onImageClick(index) {
    let loadList = await getImagesUrl(this.imagesList[index]);
    this.lightbox = loadList;
    this.setState({ isOpen: true, photoIndex: 0 });
  }

  onLightboxClose = () => {
    this.setState({ isOpen: false, photoIndex: 0 });
  };

  //load the images from the collections
  async loadImages() {
    let preList = [];
    let showNext = false;
    let showPrev = false;

    if (this.state.index > 0) {
      showPrev = true;
    }
    //pak de index + 30 om in te laden
    if (this.state.index + 30 < this.imagesList.length) {
      preList = this.imagesList.slice(this.state.index, this.state.index + 30);
      showNext = true;
    } else {
      preList = this.imagesList.slice(this.state.index, this.imagesList.length);
    }

    //put the first image of every sublist in preList
    let subbedPreList = [];
    for (let i = 0; i < preList.length; i++) {
      let subdir = preList[i];
      if (subdir.length > 0) {
        subbedPreList.push(subdir[0]);
      }
    }

    let loadList = await getImagesUrl(subbedPreList);

    this.loadSize = loadList.length;

    let classes = ['big-img', 'small-img'];

    const imagecontainer = loadList.map((name, index) => {
      let rndm = this.randomIntFromInterval(0, 1);
      let classname = 'img-fluid image scale-on-hover ' + classes[rndm];
      let img = (
        <img
          key={index}
          onLoad={this.imageLoaded}
          className={classname}
          onClick={() => this.onImageClick(index + this.state.index)}
          alt=''
          src={name}
        />
      );

      return (
        <div key={index} className='grid-item project-sidebar-card'>
          {img}
        </div>
      );
    });

    let stackList = this.stack.concat(imagecontainer);
    this.stack = stackList;

    if (this._mounted) {
      this.setState({ images: imagecontainer, showNext, showPrev });
    }
  }

  imageLoaded = () => {
    this.imagesLoaded++;

    if (this.imagesLoaded === this.loadSize || this.imagesLoaded === 5) {
      var row = document.querySelector('.loaderRow');
      row.style.visibility = 'visible';

      this.setState({ loading: false }, function () {
        document.getElementById('pages').style.visibility = 'visible';
      });
    }
  };

  render() {
    var loadingStyle = {
      visibility: 'hidden',
    };
    var loadingCheck = {
      visibility: 'visible',
      textAlign: 'center',
    };
    const breakpointColumnsObj = {
      default: 3,
      1100: 3,
      700: 2,
      500: 1,
    };

    const { photoIndex, isOpen } = this.state;

    if (!this.state.loading) {
      loadingCheck = { visibility: 'hidden' };
    }
    return (
      <div>
        <Styles>
          {this.state.loading && (
            <div style={loadingCheck}>
              <h1>Loading</h1>
              <Loader type='ThreeDots' color='#222' height='100' width='100' />
            </div>
          )}
          {this.state.error && (
            <div style={loadingCheck}>
              <h1>whoops, er is iets verkeerd gegaan</h1>
              <p>
                Herlaad de pagina, help dit niet? neem dam contact met mij op!
              </p>
            </div>
          )}
          <Masonry
            breakpointCols={breakpointColumnsObj}
            style={loadingStyle}
            className='my-masonry-grid loaderRow'
            columnClassName='my-masonry-grid_column'
          >
            {this.state.images}
          </Masonry>
          <Container id='btnContainer'>
            <Row>
              <Col>
                {this.state.showPrev && (
                  <Button variant='dark' onClick={this.prev} id='prev'>
                    {'<'}
                  </Button>
                )}
              </Col>
              <Col id='pages'>
                <span id='pageSpan'>
                  {this.state.pageIndex}/{this.pages}
                </span>
              </Col>
              <Col>
                {this.state.showNext && (
                  <Button variant='dark' onClick={this.next} id='next'>
                    {'>'}
                  </Button>
                )}
              </Col>
            </Row>
          </Container>
        </Styles>
        {isOpen && (
          <LightboxR images={this.lightbox} close={this.onLightboxClose} />
        )}
      </div>
    );
  }
}

export default PortfolioImages;
